<template>
  <div>
    <v-row class="mr-0" id="step4-right-page">
      <v-col cols="12" class="pb-0">
        <div class="font-weight-bold d-flex align-center">
          Donor Tag
          <v-btn class="mx-1 secondary" :disabled="picklistLoading || donorLoading" x-small icon dark @click="getPicklistData('donor')">
              <v-icon dark size="15" v-if="!donorLoading">mdi-sync</v-icon>
              <v-progress-circular v-else size="15" :width="3" color="white" indeterminate></v-progress-circular>
          </v-btn>
        </div>
      </v-col>
      <div class="w-full" v-if="donorLoading || picklistLoading">
          <v-skeleton-loader :loading="true" type="list-item-three-line"></v-skeleton-loader>
      </div>
      <v-radio-group v-model="model.donor_tag" class="mt-0" row dense hide-details="auto" v-if="!donorLoading && !picklistLoading">
        <v-col cols="6" class="pb-0" v-for="(item, i) in Story_Type__c" :key="i">
          <v-radio class="text-capitalize" :label="item.text" :value="item.value"></v-radio>
        </v-col>
      </v-radio-group>
      <v-col cols="12" class="pb-0">
        <div class="font-weight-bold d-flex align-center">
          Story Tag
          <v-btn class="mx-1 secondary" :disabled="picklistLoading || storyLoading || searchPickLsLoading" x-small icon dark @click="getPicklistData('story')">
              <v-icon dark size="15" v-if="!storyLoading">mdi-sync</v-icon>
              <v-progress-circular v-else size="15" :width="3" color="white" indeterminate></v-progress-circular>
          </v-btn>
        </div>
      </v-col>
      <v-col :cols="model.story_tag ? '6' :'12'">
        <div class="skeleton-list-item w-full" v-if="storyLoading || picklistLoading || searchPickLsLoading">
            <v-skeleton-loader :loading="true" type="list-item"></v-skeleton-loader>
        </div>
        <v-autocomplete v-if="!storyLoading && !picklistLoading"
            v-model="model.story_tag" no-data-text="No Story Tags Available"
            hide-details="auto" label="" placeholder="Select Best Match"
            :items="Story_About__c" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
            outlined
        />
      </v-col>
      <v-col v-if="model.story_tag" cols="6" class="d-flex align-center">
        <v-btn  color="secondary white--text" rounded type="button" @click="$emit('sendSlack');"
          :loading="slackLoading" :disabled="storyLoading"
          >Send to slack <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="model.story_tag" cols="12">
          <div class="font-weight-bold mb-3">Story Details</div>
          <v-textarea hide-details="auto"
            filled
            auto-grow
            rows="4"
            v-model.camel="model.story_details"
            row-height="20"
          ></v-textarea>
      </v-col>
      <v-col cols="12" class="pb-0">
        <div class="font-weight-bold">Which age bracket best describes you?</div>
      </v-col>
      <v-radio-group v-model="model.questionnaire[0].ans" class="mt-0" row dense hide-details="auto">
        <v-col cols="12" class="pb-0" v-for="(option, index) in ageBracketOptions" :key="index">
          <v-radio class="text-capitalize" :label="option" :value="option"></v-radio>
        </v-col>
      </v-radio-group>
      <v-col cols="12" class="pb-0">
        <div class="font-weight-bold">Which of the following statements best describe you?</div>
      </v-col>
      <v-radio-group v-model="model.questionnaire[1].ans" class="mt-0" row dense hide-details="auto">
        <v-col cols="12" class="pb-0" v-for="(option, index) in faithOptions" :key="index">
          <v-radio class="text-capitalize" :label="option" :value="option"></v-radio>
        </v-col>
      </v-radio-group>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: "stepFour",
  props: ["v", "storytag", "storytype", "slackLoading", "storyLoading"],
  emits: ["update", "sendSlack"],
  data() {
    return {
      valid: {},
      Story_About__c: [],
      Story_Type__c: [],
      picklistLoading: false,
      donorLoading: false,
      ageBracketOptions: [
        "75 years and over",
        "55-74",
        "45-54",
        "35-44",
        "25-34",
        "18-24",
        "Under 18"
      ],
      faithOptions: [
        "I don't follow any distinct faith-based beliefs",
        "I am somewhat open to faith and God",
        "I believe in Jesus and want to explore and understand more",
        "I love and follow Jesus, and desire to be more like him",
        "I follow another faith or religion",
      ]
    }
  },
  computed: {
    ...mapGetters(['searchPickLsLoading']),

    model: {
        get() {
            return this.$store.getters["model"];
        },
        set(newValue) {
            return this.$store.commit("setModel", newValue);
        },
    }
  },
  watch: {
    storytag(newVal) {
      this.Story_About__c = newVal;
    },
    storytype(newVal) {
      this.Story_Type__c = newVal;
    },
    'model.story_details': function(newVal) {
      if(newVal) this.model.story_details = this.$filters.titleCase(newVal);
    }
  },
  created() {
    this.Story_About__c = this.storytag;
    this.Story_Type__c = this.storytype;
    this.model = {...{
      donor_tag: '',
      story_tag: '',
      send_slack: 0,
      questionnaire: [
        { qn: "Which age bracket best describes you?", ans: "" },
        { qn: "Which of the following statements best describe you?", ans: "" }
      ]
    }, ...this.model};
    this.valid = this.v;
  },
  methods: {
    ...mapActions(['getSearchPicklist']),

    getPicklistData(type) {
      let reqData = [];
      const tagObject = { donor : {object: "Story__c", field: "Story_Type__c"},
        story: {object: "Story__c", field: "Story_About__c"}};
      if (!type) {
        this.picklistLoading = true;
        reqData = tagObject;
      } else {
        this[`${type}Loading`] = true;
        reqData.push({...tagObject[type], ...{force: 1}});
      }
      this.getSearchPicklist(reqData).then((response) => {
          if (!_.isEmpty(response)) {
              Object.keys(response).forEach(obj => {
                  this[obj] = response[obj];
              });
          }
          if (type ) this[`${type}Loading`] = false;
          else this.picklistLoading= false;
      }).catch(err => {
        if (type ) this[`${type}Loading`] = false;
        else this.picklistLoading = false;
      });
    },
  }
};
</script>
